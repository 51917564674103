<template>
  <div class="flex flex-col justify-center my-6 space-y-4 w-4/5 mx-auto">
    <div class="bg-white rounded-xl p-4 flex space-x-6">
      <div class="rounded-full bg-gray-100 text-gray-600 p-3 w-12 h-12 flex justify-center items-center text-3xl">
        1
      </div>
      <div class="flex flex-col items-center justify-center flex-grow space-y-4">
        <span class="text-gray-600 text-2xl">
          num. contrassegno ZTL
            <span class="font-bold">
               {{ subscriptionId }}
            </span>
        </span>
        <span class="text-gray-600 text-2xl">
          la vetrofania deve essere associata ad una pratica
          <span class="font-bold">
            muvin
          </span>
          ?
        </span>
        <div class="flex flex-row space-x-4" v-show="step == 1">
          <span class="px-3 py-1 rounded-lg bg-green-200 text-green-700 text-xl cursor-pointer select-none" @click="step++, fromMuvin = true">
            si
          </span>
          <span class="px-3 py-1 rounded-lg bg-red-200 text-red-700 text-xl cursor-pointer select-none" @click="nextStep()">
            no
          </span>
        </div>
      </div>
    </div>
    <div v-if="fromMuvin" class="bg-white rounded-xl p-4 flex space-x-6">
      <div class="rounded-full bg-gray-100 text-gray-600 p-3 w-12 h-12 flex justify-center items-center text-3xl">
        2
      </div>
      <div class="flex flex-col items-center justify-center flex-grow space-y-4">
        <span class="text-gray-600 text-2xl">
          inserire numero pratica muvin
        </span>
        <input v-model="muvinPracticeId" type="text" name="muvinPracticeId" class="border-2 px-1 py-2 text-lg rounded-lg" placeholder="num. pratica" @keypress.enter="nextStep()" :readonly="showCam == true" />
        <span class="px-3 py-1 rounded-lg bg-green-200 text-green-700 text-xl cursor-pointer select-none" @click="nextStep()" v-show="showCam == false">
          conferma
        </span>
      </div>
    </div>
    <div v-if="showCam == true" class="bg-white rounded-xl p-4 flex space-x-6">
      <div class="rounded-full bg-gray-100 text-gray-600 p-3 w-12 h-12 flex justify-center items-center text-3xl">
        {{ fromMuvin ? '3' : '2' }}
      </div>
      <div class="flex flex-col items-center justify-center flex-grow space-y-4">
        <span class="text-gray-600 text-2xl">
          scansionare QR code contrassegno
        </span>
        <div v-if="scanner" class="h-24">
          <form @submit.prevent="associateQr(qrCode)">
            <input id="inputScanner" class="border-2 px-1 py-2 text-lg rounded-lg" type="text" v-model="qrCode" placeholder="scansionare QR.." v-focus autofocus />
          </form>
        </div>
        <div v-else class="h-96">
          <qrcode-stream @decode="associateQr">
          </qrcode-stream>
        </div>
      </div>
    </div>
  </div>
  <!-- Loader -->
  <div v-if="showLoader" class="absolute z-50 w-screen h-screen text-gray-600 top-0 left-0 items-center justify-center flex text-3xl transform transition-all duration-300" :style="{ background: loaderBgColor }">
    {{ loaderText }}
  </div>
  <!-- End Loader -->
</template>

<script>
import { QrcodeStream } from "vue3-qrcode-reader";
import axios from "axios";

export default {
  name: "AssociateQR",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      qrCode: null,
      qrReaded: false,
      muvinPracticeId: null,
      showCam: false,
      token: null,
      showLoader: false,
      fromMuvin: false,
      step: 1,
      loaderText: 'associazione vetrofania in corso... attendere...',
      loaderBgColor: '#F9FAFB',
      timestamp: null,
      pageId: null,
    };
  },
  directives: {
    focus: {
      updated: function (el) {
        el.focus()
      }
    }
  },
  computed: {
    subscriptionId() {
      if (this.$route.params.subscriptionid != null) {
        return this.$route.params.subscriptionid
      } else {
        return null
      }
    },
    muvinUrlName() {
      return this.$route.params.muvinurlname != null ? this.$route.params.muvinurlname : null
    },
    scanner () {
      if (this.$route.params.scanner == 1) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    pageId(value) {
      localStorage.pageId = value;
    }
  },
  mounted() {
    window.addEventListener('storage', this.viewOtherTabs)
    this.timestamp = Date.now()
    localStorage.pageId = this.timestamp

    let data = {
      user: process.env.VUE_APP_QR_USERNAME,
      password: process.env.VUE_APP_QR_PASSWORD
    }
    // get api token
    axios.post(process.env.VUE_APP_CS_API_URL + '/login', data, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((response) => {
      this.token = response.data.token
    })
    .catch(() => {
      this.$toast.error('Autorizzazione Token non ottenuta')
    })

  },
  beforeUnmount() {
    window.addEventListener('storage', this.viewOtherTabs)
  },
  methods: {
    nextStep() {
      if (this.fromMuvin == true && this.muvinPracticeId == null) {
        this.$toast.error('Autorizzazione Token non ottenuta')
      }

      if (this.subscriptionId == null) {
        return this.$toast.error('Contrassegno ZTL21 non valido')
      }

      if (this.muvinUrlName == null) {
        return this.$toast.error('Nome comune non valido')
      }

      if (this.token == null) {
        return this.$toast.error('Token non valido')
      }

      this.step++

      if (this.fromMuvin == true) {
        axios.get(process.env.VUE_APP_MUVIN_API_URL + '/dossier/' + this.muvinPracticeId, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }).then((response) => {
          if (response.data == "") {
            return this.$toast.error('Pratica muvin num. ' + this.muvinPracticeId + ' non esistente')
          }
          this.showCam = true
        })
      } else {
        this.showCam = true
      }

    },
    associateQr(code) {
      if (code == null || code == "" || this.qrReaded == true) {
        return false
      }

      this.$toast.clear()
      this.qrCode = code
      this.qrReaded = true
      this.showLoader = true
      document.body.style.overflow = "hidden"

      let data = {
        'qrcode': this.qrCode,
        'city_name': this.muvinUrlName,
        'subscription_id': this.subscriptionId,
        'muvin_practice_id': this.muvinPracticeId != null ? this.muvinPracticeId : ''
      }

      axios.put(process.env.VUE_APP_CS_API_URL + '/associateqrcode', data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        }
      }).then((response) => {
        let status = response.status
        if (status == 200) {
          this.loaderText = 'vetrofania associata con successo... attendere...'
          this.loaderBgColor = '#A7F3D0'
          setTimeout(this.closeWindow, 2000)
        } else {
          this.showLoader = false
          this.$toast.error('Errore associazione vetrofania')
          if (this.scanner) {
            this.scanner = null
            document.getElementById('inputScanner').focus()
          }
        }
      }
      ).catch((error) => {
        this.showLoader = false
        this.qrReaded = false
        this.qrCode = null
        let status = error.response.status
        switch (status) {
          case 403:
            this.$toast.show('Codice vetrofania già utilizzato', {
              type: 'error',
              duration: 4000
            })
            break;
          case 404:
            this.$toast.show('Codice vetrofania inesistente', {
              type: 'error',
              duration: 4000
            })
            break;
          case 422:
            this.$toast.show('Errore generale - Richiedere assistenza', {
              type: 'error',
              duration: false
            })
            break;
          case 500:
            this.$toast.show('Errore generale - Richiedere assistenza', {
              type: 'error',
              duration: false
            })
            break;
        }
      })
    },
    closeWindow() {
      window.close()
    },
    viewOtherTabs(event) {
      if (event.key == 'pageId') {
        this.pageId = event.newValue
        if (localStorage.pageId > this.timestamp) {
          window.close()
        }
      }
    }
  },
};
</script>
<style>
body {
  background: #F9FAFB;
}
</style>