import { createRouter, createWebHashHistory } from 'vue-router'
import AssociateQR from '@/components/AssociateQR.vue'

const routes = [
  {
    path: '/associate-qr-to/:muvinurlname/:scanner/:subscriptionid',
    name: 'AssociateQR',
    component: AssociateQR
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
